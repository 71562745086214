.container {
  position: relative;
  width: 960px;
  padding-bottom: 20px;
  margin: 0 auto;
}

.container-fluid {
  min-width: 1600px;
  padding-bottom: 20px;
  margin: 0 20px;
}

.grid {
  --noOfColumns: 1;

  display: grid;
  grid-column-gap: 32px;
  grid-template-columns: repeat(var(--noOfColumns), minmax(0, 1fr));
}

.grid--card {
  grid-column-gap: 20px;
}

.grid--form {
  grid-column-gap: 8px;
}

.grid--col-1 {
  --noOfColumns: 1;
}

.grid--col-2 {
  --noOfColumns: 2;
}

.grid--col-3 {
  --noOfColumns: 3;
}

.grid--col-4 {
  --noOfColumns: 4;
}

.grid--col-5 {
  --noOfColumns: 5;
}

.grid--col-6 {
  --noOfColumns: 6;
}

.grid--col-7 {
  --noOfColumns: 7;
}

.grid--col-8 {
  --noOfColumns: 8;
}

.grid--col-9 {
  --noOfColumns: 9;
}

.grid--col-10 {
  --noOfColumns: 10;
}

.grid--col-11 {
  --noOfColumns: 11;
}

.grid--col-12 {
  --noOfColumns: 12;
}

.col-1 {
  grid-column: span 1;
}

.col-2 {
  grid-column: span 2;
}

.col-3 {
  grid-column: span 3;
}

.col-4 {
  grid-column: span 4;
}

.col-5 {
  grid-column: span 5;
}

.col-6 {
  grid-column: span 6;
}

.col-7 {
  grid-column: span 7;
}

.col-8 {
  grid-column: span 8;
}

.col-9 {
  grid-column: span 9;
}

.col-10 {
  grid-column: span 10;
}

.col-11 {
  grid-column: span 11;
}

.col-12 {
  grid-column: span 12;
}
