@font-face {
  font-family: "PFAdamantSansPro";
  src: url("./PFAdamantSansPro-Light.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "PFAdamantSansPro";
  src: url("./PFAdamantSansPro-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "PFAdamantSansPro";
  src: url("./PFAdamantSansPro-Thin.woff2") format("woff2");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "PFAdamantSansPro";
  src: url("./PFAdamantSansPro-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
