@import "~normalize.css";
@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/datetime/lib/css/blueprint-datetime.css";
@import "~@blueprintjs/popover2/lib/css/blueprint-popover2.css";
@import "./assets/fonts/fonts.css";
@import "./assets/styles/grid.css";

html {
  height: 100%;
}

body {
  min-width: 980px;
  height: 100%;
  font-family: "PFAdamantSansPro", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}

#root {
  min-height: 100%;
}

img:not([class]),
svg:not([class]) {
  display: block;
  max-width: 100%;
}

thead.valign-middle tr th,
tbody.valign-middle tr td {
  vertical-align: middle;
}

tr.toolbar td {
  padding: 4px;
}

tr.toolbar input,
tr.toolbar button,
tr.toolbar select {
  font-size: 12px;
}

tr.toolbar .bp3-popover2-target {
  width: 100%;
}

tr.bp3-intent-warning td {
  background-color: rgba(217, 130, 43, 0.15);
}
